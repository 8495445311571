.login-form {

    padding: 1rem 1.5rem;
    background-color: $light-grey;
    max-width: 350px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
    -webkit-transform: translate(-50%, -70%);

    h1 {
        font-size: 1.7rem;
        margin: 0 0 1rem 0;
    }

}

.smallish-form {

    max-width: 400px;
    margin: 0 auto;
    width: 100%;
    padding: 1rem 1.5rem;
    
    p.help-block {
        font-size: 0.9rem;
        font-color: $mid-grey;
    }
}

form {

    .form-container {
        padding: 1rem;
        margin: 1rem 0;
        background-color: $light-grey;
    }

    .group {

        margin-bottom: 1rem;
        
        label {
            display: block;
            font-size: 1rem;
            font-weight: 700;
        }

        input, select, textarea {
            width: 100%;
            font-size: 1rem;
            padding: 0.35rem 0.5rem;
        }

        input[type=radio] {
            width: auto;
        }

        .radio-group {
            display: inline-block;
            margin-right: 1rem;
        }

        select {
            width: 100%;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 0;
            background-color: #fff;
            border: 1px solid lighten($light-grey, 70%);
            background-image:
             linear-gradient(45deg, transparent 50%, gray 50%),
             linear-gradient(135deg, gray 50%, transparent 50%),
             linear-gradient(to right, #ccc, #ccc);
            background-position:
             calc(100% - 20px) calc(1em + 2px),
             calc(100% - 15px) calc(1em + 2px),
             calc(100% - 2.5em) 0.5em;
            background-size:
             5px 5px,
             5px 5px,
             1px 1.5em;
             background-repeat: no-repeat;
        }

        select::-ms-expand { /* for IE 11 */
            display: none;
        } 

        textarea {
            border: 1px solid lighten($light-grey, 70%);
        }

        input[type="checkbox"] {
            display: inline-block;
            width: auto;
        }

        .checkbox-label {
            font-weight: 500;
            display: inline-block;
        }

        p.input {
            margin: 0;
            padding: 0.35rem 0.5rem;
            font-size: 1rem;
        }

    }


    button {
        background-color: $brand-color;
        color: #fff;
        padding: 0.5rem 1.5rem;
        border: none;
        font-size: 1rem;
    }

}
