.modal {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
    -webkit-transform: translate(-50%, -70%);
    width: 100%;
    max-width: 450px;
    background-color: #fff;
    padding: 2rem 1.5rem;
    border-top: 3px solid $brand-color;

    h2 {
        margin: 0;
    }

}

.dimmer {

  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';

}

.hide {
    display: none;
}
