// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

html, body {
    height: 100%;
}

.page-wrap {
    margin: 0;
    min-height: 100%;
    margin-bottom: calc(-4.8rem - 1px);
}

.page-wrap:after {
    content: "";
    display: block;
    height: calc(4.8rem + 1px);
}

.footer {
    font-size: 0.8rem;
    color: $mid-grey;
    border-top: 1px solid $light-grey;
    padding: 2rem 0;

    .footer-content {
        max-width: 750px;
        margin: 0 auto;
    }
}
