.breadcrumbs {
    font-size: 0.9rem;
    margin: 1rem 0;

    a {
        color: $mid-grey;
    }

    .zondicon {
        height: 0.9rem;
        width: 0.9rem;
    }

}
