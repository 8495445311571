// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.button {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    background-color: $light-grey;
    color: $text-color;
    margin-right: 0.5rem;
}

.button:hover {
    background-color: darken($light-grey, 10%);
    text-decoration: none;
}

.delete-button {
    background-color: $red-color;
    color: #fff;
    padding: 0.5rem 1.5rem;
}

.delete-button:hover {
    background-color: darken($red-color, 10%);
    color: #fff;
}

.new-button {
    font-size: 0.9rem;
    background-color: $light-grey;
    float: right;
    margin-top: -3.4rem;
    color: $text-color;
    padding: 0.4rem 0.7rem;
}

.new-button:hover {
    background-color: $brand-color;
    text-decoration: none;
    color: #fff;
}
