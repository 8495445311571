// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.header {

    width: 100%;
    background-color: $brand-color;
    color: #fff;
    margin: 0;
    padding: 0;

    .container {
        position: relative;
    }

    .brand {
        
        margin: 0 auto;
        padding: 1rem 0;
        text-align: center;

        a {
            color: #fff;
            font-size: 1rem;
            font-weight: 700;

            svg {
                fill: #fff;
                height: 1rem;
                width: 1rem;
            }
        }

        a:hover {
            text-decoration: none;
        }

    }

    .nav {

        position: absolute;
        top: 1rem;
        right: 0;

        a {
            color: #fff;
            font-size: 1rem;
            padding: 0.25rem 0.5rem;
        }

        a:hover {
            background-color: #fff;
            color: $brand-color;
            text-decoration: none;
        }

    }

}

.sub-header {

    padding: 1rem 0 0 0;
    background-color: $light-grey;
    border-bottom: 1px solid darken($light-grey, 10%);

    ul {

        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            margin: 0;
            padding: 0;

            a {
                font-size: 0.9rem;
                font-weight: 500;
                margin: 0 0.1rem;
                padding: 0.75rem 2rem;
                color: $dark-grey;
            }

            a:hover {
                text-decoration: none;
                color: $text-color;
            }

            a.selected {
                background-color: #fff;
                color: $brand-color;
                border: 1px solid darken($light-grey, 10%);
                border-bottom: none;
                border-top: 3px solid $brand-color;
            }
        }
    
    }

    .menu-fix {
        height: 0.5rem;
        content: '';
    }

}
