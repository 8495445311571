// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

.section {
    min-height: 100vh;
}

.main {

    background-color: $brand-color;
    color: #fff;
    margin: -3.4rem 0 0 0;
    position: relative;
    z-index: -1;

}

.centered {

    max-width: 750px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
    -webkit-transform: translate(-50%, -70%);
    text-align: center;
    z-index: 0;

}

.down-icon {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);

    svg {
        fill: lighten($brand-color, 50%);
        height: 2rem;
    }
}

.benefits {

    text-align: center;

    .benefit {
        max-width: 600px;
        margin: 0 auto;
        border-bottom: 1px solid $light-grey;
        padding: 1rem;

        h3 {
            margin-bottom: 0.5rem;
        }

        p {
            margin-top: 0;
        }
    }

}

.contact {

    max-width: 650px;
    margin: 0 auto 2rem auto;
    padding: 1rem;
    background-color: $light-grey;

    h2 {
        text-align: center;
        margin: 0;
    }

    p {
        text-align: center;
        color: $dark-grey;
        font-size: 1.2rem;
    }

    .half {
        width: 50%;
        float: left;
    }

    textarea {
        width: 100%;
        height: 6rem;
    }

    .group {
        padding: 0 0.5rem;
    }

}
