.albums-index-page {
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
}

.albums-list {

    .album {

        background-color: $light-grey;
        padding: 1rem 1.5rem;
        margin-bottom: 1rem;
        color: $text-color;

        .meta {

            float: left;

            .title {
                display: block;
            }

            .date {
                display: block;
                font-size: 1rem;
            }
        
        }

        .options {

            float: right;
            font-size: 1rem;
            padding-top: 0.15rem;
            text-align: right;

            .edit {
                display: block;
            }
        
            .upload {
                display: block;
            }

        }
    
    }

    .album:hover {
        background-color: darken($light-grey, 5%);
    }

    .no-albums {
        padding-top: 2rem;
        text-align: center;

        p {
            font-size: 1.1rem;
        }

        p.title {
            font-weight: 700;
        }
    }

}

.upload-button {
    float: right;
    background-color: $brand-color;
    color: #fff;
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
    margin-right: 3px;
}

.upload-button:hover {
    text-decoration: none;
    color: #fff;
    background-color: darken($brand-color, 5%);
}

.upload-box {
    width: 100%;
    min-height: 10rem;
    margin: 0.5rem 3px 0.5rem 3px;
}

.photo {
    width: calc(33.333333% - 8px);
    display: block;
    position: relative;
    float: left;
    margin: 4px;

    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.photo:after {
    padding-top: 66%;
    /* 16:9 ratio */
    display: block;
    content: '';
}

.photo-options {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: none;

    a {
        background-color: $light-grey;
        opacity: 0.6;
        color: $text-color;
        display: table-cell;
        text-align: center;
    }

    a:hover {
        opacity: 0.9;    
        background-color: #fff;
    }
}

.set-cover-photo {
    position: absolute;
    top: 0;
    right: 0.25rem;
    font-weight: 700;
    display: none;

    a {
        font-size: 0.8rem;
        background-color: $light-grey;
        opacity: 0.6;
        color: $text-color;
        padding: 0.25rem 0.4rem;
    }

    a:hover {
        opacity: 1;    
        background-color: $brand-color;
        color: #fff;
        text-decoration: none;
    }
}

.cover-photo {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $brand-color;
    text-align: center;
    padding: 0.25rem 0;

    p {
        display: none;
        font-size: 0.8rem;
        color: #fff;
        margin: 0;
    }
}

.show {
    display: block;
}

.photo:hover, .photo:focus {

    .photo-options {
        display: table;
    }
    
    .set-cover-photo {
        display: block;
    }

    .cover-photo {
        p {
            display: block;
        }
    }
}

.loading {

    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(250, 250, 250, .75);

    .loader {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    svg path,
    svg rect{
      fill: $brand-color;
    }

}

.hide-button {
    display: none !important;
}

.tooltip {
    opacity: none;
    font-size: 0.8rem;
    padding: 0.2rem;
    background-color: $dark-grey;
    color: #fff;
    position: absolute;
    top: -1.45rem;
    z-index: 1;
    visibility: hidden;
    -webkit-transition-delay: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;

}

.tooltip-right {

    right: 0;

}

.photo-options {

    a:hover {

        .tooltip {
            visibility: visible;
            -webkit-transition-delay: visibility 0s step-end 0.7s;
            -moz-transition: visibility 0s step-end 0.7s;
            -o-transition: visibility 0s step-end 0.7s;
            transition: visibility 0s step-end 0.7s;
        }
    
    }

}

.back-to-albums {

    font-size: 0.8rem;
    color: $dark-grey;
    fill: $mid-grey;
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    display: inline-block;

    .zondicon {
        width: 0.8rem;
        height: 0.8rem;
    }

}

.back-to-albums:hover {

    text-decoration: none;
    background-color: $light-grey;

}

.trashcan-button {
    background-color: $light-grey;
    color: $text-color;
    font-size: 1rem;
    padding: 0.5rem 1rem;
}

.trashcan-button:hover {
    background-color: $red-color;
    color: #fff;
}

.trashcan-album-button {
    float: right;
    margin-top: -2.25rem;

    svg {
        fill: $text-color;
        height: 1rem;
        width: 1rem;
    }
}

.delete-album-button:hover {
    svg {
        fill: #fff;
    }
}

.modal {

    .count {
        font-weight: 700;
    }

}
