// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------

.sub-page {

    max-width: 750px;
    margin: 1rem auto;

    .inner-content {
        padding: 1rem;
        border: 2px solid $light-grey;
        border-top: 0;
    }

}

.album-header {

    background-color: $light-grey;
    padding: 1rem 0 0 0;

    h1 {
        font-size: 2rem;
        margin: 0 1rem 2rem 1rem;
    }

    .sub-menu {
        
        ul {

            display: table;
            table-layout: fixed;
            list-style-type: none;
            margin: 0;
            padding: 0;
            width: 100%;

            li {
                display: table-cell;
                margin: 0;
                padding: 0;

                a {
                    display: block;
                    border: 1px solid $light-grey;
                    padding: 0.5rem 1rem;
                    font-size: 1rem;
                }

                a:hover {
                    background-color: darken($light-grey, 10%);
                }

                a.selected {
                    background-color: darken($light-grey, 10%);
                    color: $text-color;
                }

            }

        }

    }

}
