.gallery-index-page {
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
}

.gallery-list {

    .gallery {
        background-color: $light-grey;
        padding: 1rem;
        margin-bottom: 1rem;

        .meta {

            float: left;

            .name {
                display: block;
            }

            .slug {
                display: block;
                font-size: 1rem;
            }

        }

        .options {

            float: right;

            .manage {
                display: block;
                padding-top: 0.95rem;
                font-size: 1rem;
            }
        
        }

    }

}
